exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-410-js": () => import("./../../../src/pages/410.js" /* webpackChunkName: "component---src-pages-410-js" */),
  "component---src-pages-best-new-sex-toys-js": () => import("./../../../src/pages/best-new-sex-toys.js" /* webpackChunkName: "component---src-pages-best-new-sex-toys-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-register-as-user-and-submit-first-sex-toy-review-invite-js": () => import("./../../../src/pages/register-as-user-and-submit-first-sex-toy-review-invite.js" /* webpackChunkName: "component---src-pages-register-as-user-and-submit-first-sex-toy-review-invite-js" */),
  "component---src-pages-register-as-user-and-submit-first-sex-toy-review-js": () => import("./../../../src/pages/register-as-user-and-submit-first-sex-toy-review.js" /* webpackChunkName: "component---src-pages-register-as-user-and-submit-first-sex-toy-review-js" */),
  "component---src-pages-search-results-js": () => import("./../../../src/pages/search-results.js" /* webpackChunkName: "component---src-pages-search-results-js" */),
  "component---src-pages-submit-sex-toy-collection-js": () => import("./../../../src/pages/submit-sex-toy-collection.js" /* webpackChunkName: "component---src-pages-submit-sex-toy-collection-js" */),
  "component---src-pages-submit-your-own-sex-toy-review-js": () => import("./../../../src/pages/submit-your-own-sex-toy-review.js" /* webpackChunkName: "component---src-pages-submit-your-own-sex-toy-review-js" */),
  "component---src-templates-about-template-js": () => import("./../../../src/templates/about-template.js" /* webpackChunkName: "component---src-templates-about-template-js" */),
  "component---src-templates-author-page-js": () => import("./../../../src/templates/author-page.js" /* webpackChunkName: "component---src-templates-author-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-custom-contact-template-js": () => import("./../../../src/templates-custom/contact-template.js" /* webpackChunkName: "component---src-templates-custom-contact-template-js" */),
  "component---src-templates-custom-testers-template-js": () => import("./../../../src/templates-custom/testers-template.js" /* webpackChunkName: "component---src-templates-custom-testers-template-js" */),
  "component---src-templates-postcategory-template-js": () => import("./../../../src/templates/postcategory-template.js" /* webpackChunkName: "component---src-templates-postcategory-template-js" */),
  "component---src-templates-roundupcategory-js": () => import("./../../../src/templates/roundupcategory.js" /* webpackChunkName: "component---src-templates-roundupcategory-js" */),
  "component---src-templates-single-page-js": () => import("./../../../src/templates/single-page.js" /* webpackChunkName: "component---src-templates-single-page-js" */),
  "component---src-templates-single-product-js": () => import("./../../../src/templates/single-product.js" /* webpackChunkName: "component---src-templates-single-product-js" */),
  "component---src-templates-single-roundup-js": () => import("./../../../src/templates/single-roundup.js" /* webpackChunkName: "component---src-templates-single-roundup-js" */)
}

